.text-thin{
  font-weight: 100;
}

.text-extra-light{
  font-weight: 200;
}

.text-light{
  font-weight: 300;
}

.text-regular{
  font-weight: 400;
}

.text-medium{
  font-weight: 500;
}

.text-semi-bold{
  font-weight: 600;
}

.text-bold{
  font-weight: 700;
}

.text-extra-bold{
  font-weight: 800;
}

.text-black{
  font-weight: 900;
}

.no-text-decoration{
  text-decoration: none !important;
}

h1, h2, h3, h4, h5, h6, p{
  margin:0 0 15px 0;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6{
  margin:0 0 15px 0;
  //font-weight: $font-weight-base;
  font-family: $secondary-font;
  color:$color-blue-gray;
}

h1{
  font-size: $font-size-h1;
}

h2{
  font-size: $font-size-h2;
}

h3{
  font-size: $font-size-h3;
}

h4{
  font-size: $font-size-h4;
}

h5{
  font-size: $font-size-h5;
}

h6{
  font-size: $font-size-h6;
}

b, strong{
  font-weight: 700;
}
.span-red{
    color:$color-red;
    font-weight: 700;
}
.ProtectionPaid{
     color:$color-green;
}
.ProtectionUnPaid{
    color:$color-red;
}