﻿.table-title {
    background: white;
    color: $color-blue-title;
    padding: 15px 0;
    border-radius: 10px 10px 0 0;
    margin: 0;

    h2 {
        margin: 10px 0 0 0;
        font-size: 25px;
        
        img {
            margin-right: 10px;
            vertical-align: initial;
        }
    }

    @media (max-width: $screen-sm-max) {
        .btn-red {
            margin-top: 20px;
        }
    }
}

.table-wrapper {
    overflow-x: auto;
    text-align:center;
}
table.table, .simple-table table, #MembreListDiv {
    width: 100%;
    margin: 0 auto;
    background-color: white;

    tr {
        td, th{
            border: 1px solid $color-grey-3;
            margin:0;
            padding:0 20px;
            vertical-align:middle;
            height:60px;
            text-align:center;
        }
        td {
            &.contact-col{
                height:120px;
                .strong{
                  font-size:18px;
                  line-height:20px;
                  color:$color-red;
                  font-weight:600;
                  span{
                       font-size:14px;
                       margin-bottom:4px;
                       display:block;
                  }
                } 
                    p{
                        font-size:14px;
                      line-height:16px;
                      margin:0;
                      color:$color-dark-blue-text;
                  }
            }
            &.action-col{
                padding:0;
                width:60px;
            }     
            &.row-seperator{
                height:20px;
                border:none;
            }
            &.btn-row{
                width:230px;
            }
            .no-data{
               width:20%;
               display:block;
               margin: 0 auto;
               border-bottom:1px solid  $color-light-blue;
            }
        }
        td.header, th{            
                color:$color-light-blue;
                font-weight:300;
            }
    }
}


/*table {
    width: 100%;
    margin: 0;
    margin-bottom:0 !important;

    th, td {
        padding: 20px 5px;
        text-align: center;
        line-height: 18px;
    }

    thead {
        tr {
            background-color: $color-grey-3;
            th {
                border-top: none;
            }
        }
    }

    tbody {
        td {
            font-size: 16px;
        }
    }

    .spacer {
        width: 35px;
    }

    .btn-row {
        text-align: right !important;

        .btn {
            margin-left: 5px;
        }
    }

    .btn {
        margin-bottom: 0;
    }

    a:not(.btn) {
        text-decoration: underline;
    }
}

.full-table {
    table {
        tbody {
            tr {
                background: url("../img/points.png") top repeat-x;

                &:nth-child(odd) {
                    background-color: $color-grey-3;
                }

                &:nth-child(even) {
                    background-color: $color-grey-2;
                }

                &:hover {
                    > td:first-child {
                        border-left: $color-red 20px solid;
                    }
                }

                &:not(:hover) {
                    .btn-blue, .btn-darkblue {
                        @extend .unselected;
                    }
                }

            }
        }
    }
}

.full-table-2-lines {
    table {
        tbody {
            tr {
                &:nth-child(odd) {
                    background: url("../img/points.png") top repeat-x;
                    background-color: $color-grey-3;
                }

                &:nth-child(even) {
                    background-color: $color-grey-2;
                }
            }

            &:hover {
                > tr td:first-child {
                    border-left: $color-red 20px solid;
                }
            }

            &:not(:hover) {
                .btn-blue, .btn-darkblue {
                    @extend .unselected;
                }
            }
        }
    }
}

.simple-table {
    table {
        tbody {
            tr {
                background: url("../img/points.png") top repeat-x;

                &:nth-child(odd) {
                    background-color: $color-grey-3;
                }

                &:nth-child(even) {
                    background-color: $color-grey-2;
                }

                td:not(:first-child) {
                    position:relative;
                    &:before {
                        content: "|";
                        position: absolute;
                        left:0;
                    }
                }
            }
        }
    }
}*/
