﻿


@media(min-width:768px) {
    .edit-membre {
        .nav-tabs {
            display: flex !important;
        }

        .card {
            border: none;
        }

        .card .card-header {
            display: none;
        }

        .card .collapse {
            display: flex;
        }
    }
}

@media(max-width:767px) {
}

.edit-membre {
    .tab-content {
        padding-top: 1px;
    }

    .nav-tabs {
        display: none;
        border-bottom: none;
        align-items: flex-end;

        .nav-item {
            margin-right: 5px;

            .nav-link {
                font-family: $secondary-font;
                position: relative;
                color: white;
                font-weight: 300;
                font-size: 16px;
                background-color: $color-blue-gray;
                border-radius: 10px 10px 0 0;
                border: none;
                padding: 15px 40px;

                &.active {
                    box-shadow: 0 0 20px 1px rgba(0,0,0,0.2);
                    background-color: white;
                    color: $color-blue;

                    .hide-shadow {
                        position: absolute;
                        background-color: white;
                        width: 100%;
                        height: 18px;
                        left: 0;
                        bottom: 0px;
                        z-index: 199;
                    }
                }
            }
        }
    }
}

section.edit-membre {
    .nav-tabs .nav-item .nav-link {
        &.profile {
            background-image: url('../img/member-red-icon.png');
            background-repeat: no-repeat;
            background-position: left 20px center;
            padding-left: 50px;

            @media (max-width: $screen-sm-max) {
                background-image: none;
            }
        }
    }
}

.inner-collaps {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}

@include media-breakpoint-down(sm) {
    section.edit-membre .nav-tabs .nav-item .nav-link.profile {
        padding-left: 20px;

        &.active {
            padding-left: 25px;
        }
    }

    .edit-membre {
        .tab-content {
            border-radius: 0;
            padding: 0;
            margin-bottom: 16px;
        }

        .tab-pane {
            display: block !important;
            opacity: 1;
            padding: 0px !important;
            margin-bottom: 0 !important;

            .collapse {
                //padding: 0 40px 40px 40px;
                h4 {
                    font-size: 18px;
                }
            }

            &.card {
                border: 0;
            }

            &.active {
                .card-header {
                    //box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.2);
                    background-color: white;

                    a {
                        color: #004478;

                        &:focus,
                        &:hover {
                            text-decoration: none
                        }
                    }
                }
            }

            .card-header {
                position: relative;
                font-weight: 300;
                font-size: 16px;
                background-color: #597a96;

                a {
                    color: white;

                    &:focus,
                    &:hover {
                        text-decoration: none
                    }
                }
            }

            &.card,
            .card-header:first-child {
                border-radius: 0;
            }
        }

        .validation-summary-valid ul {
            margin: 0;
        }

        .inner-collaps {
            padding: 20px;
        }
    }
    /*
    .nav-tabs {


        .nav-item {
            margin-right: 0;
            border-bottom: 2px solid white;
            .nav-link {
                padding: 10px 20px;
                font-size: 16px;

                &.active {
                    padding: 12px 25px;
                }
            }

            &:not(:first-child) {
                .nav-link {
                    border-radius: 0;
                }
            }
        }
    }
        */
}

@include media-breakpoint-only(md) {
    /**/
    .edit-membre {
        .nav-tabs .nav-item .nav-link {
            padding: 15px 24px;
        }
    }
}
