section {
    &.search {
        text-align: center;

        form {

            input, select {
                width: 100%;
            }

            a.search {
                font-family: $secondary-font;
                font-weight: 700;
                color: $color-blue-title;
            }
        }

        .btn {
            margin-bottom: 0px;
        }
    }

    &.members {
        table {
            .member-number {
                text-align: left;
                font-weight: 700;

                &.new, &.add, &.renew {
                    padding-left: 40px;

                    span {
                        text-transform: uppercase;
                        color: $color-red;
                        font-size: 12px;
                    }
                }

                &.new {
                    background: url("../img/member-red-icon.png") left center no-repeat;
                }

                &.add {
                    background: url("../img/member-plus-red-icon.png") left center no-repeat;
                }

                &.renew {
                    background: url("../img/member-renew-red-icon.png") left center no-repeat;
                }
            }

            .beneficiary {
                position: relative;
                background: url("../img/member-beneficiary-icon.png") center center no-repeat;
                width: 60px;

                span {
                    display: block;
                    position: absolute;
                    background-color: $color-blue-gray;
                    color: white;
                    font-weight: 700;
                    line-height: 20px;
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                    top: 20px;
                    right: 0px;

                    a, a:hover {
                        color: white;
                    }
                }

                &.renew {
                    span {
                        background-color: $color-red;
                    }
                }
            }
        }
    }

    &.edit-membre {


        #business {
            //display: none;
        }

        .table-wrapper {
            margin-bottom: 20px;
        }
    }

    &.edit-protections {
        .table-wrapper {
            margin-bottom: 20px;
        }
    }

    &.roles {
        h2 {
            padding: 20px 0 10px;
        }

        table {
            th:first-child, td:first-child {
                text-align: left !important;
                padding-left: 20px;
            }

            input[type="checkbox"] {
                margin-right: 5px;
            }
        }
    }

    &.login {
        padding: 0;

        .container .box {
            background-color: rgba(255,255,255,0.7);
            border: 1px solid #d1dee5;
            border-top: none;
            width: 400px;
            padding: 30px;
            h2{
                font-weight: 700;
                margin-bottom: 40px;
                font-size: 2rem;
            }

            .form-button {
                margin-top:40px;
                .btn {
                    margin-bottom: 0;
                }
                .password {
                    color: $color-dark-blue;
                    font-size: 14px;
                    //margin-left: 20px;
                    padding-top: 20px;
                    display: block;
                }
            }
            p{
                border-top:1px solid lighten($color-dark-blue-text, 40%);
                margin-top:40px;
                padding-top:20px;
                font-size:14px;
                color:lighten($color-dark-blue-text, 40%);
            }
        }
    }
}
