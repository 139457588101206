section {
    padding: 10px 0;

    &.title {
        margin-top: 30px;
        padding-bottom: 10px;

        h1 {
            font-size: 34px;
            font-family: $secondary-font;
            color: $color-blue;
            margin-bottom: 0;
            sup{
                font-size:50%;
            }
            a, a:hover {
                color: $color-blue-gray;
                font-weight: 300;
                font-size: 18px;
            }
        }
        .action-menu{
            margin-top:10px;
            div{
                display:inline-block;
                &:not(:first-child){
                    margin-left:15px;
                    padding-left:15px;
                 border-left:1px solid lighten($color-light-blue, 30%);
                }
            }
        }
    }
}
.modal-body {
    .title {
        margin-top: 10px;
    }

    .closemodal:not(.btn) {
        right: 20px;
    }
}

    a.deleteEmail {
        position: absolute;
        top: 6px;
        right: -18px;
        z-index: 999;

        .fa-remove {
            color: $color-red;
        }
    }

    hr {
        border-top: 2px solid rgba(0, 0, 0, 0.1);
    }

    .content-wrapper {
        box-shadow: 0 0 10px 0px rgba(0,0,0,0.2);
        border-radius: 10px;
        background: white;
        margin-bottom: 20px;
        padding: 40px;

        &.table {
            padding: 0px;
            background: $color-grey-2;
        }

        .table-wrapper {
            border-radius: 0 0 10px 10px;
        }
    }
    /*.table-title {
    background: $color-white-title;
    color: $color-blue-title;
    padding: 35px;
    border-radius: 10px 10px 0 0;
    margin: 0;

    h2 {
        margin: 0;
        font-size: 25px;

        img {
            margin-right: 10px;
            vertical-align: initial;
        }
    }

    @media (max-width: $screen-sm-max) {
        .btn-red {
            margin-top: 20px;
        }
    }
}*/
    /*.table-wrapper {
    overflow-x: auto;

    table {
        width: 100%;
        margin: 0;

        th, td {
            padding: 20px 5px;
            text-align: center;
            line-height: 18px;
        }

        th {
            border-top: none;
        }

        .btn {
            margin-bottom: 0;
        }
    }

    a:not(.btn){
        text-decoration: underline;
    }
}
*/
    .chosen-container-single {
        width: 100% !important;
        max-width: 100% !important;
        min-width: 100px !important;
        font-family: $primary-font;

        .chosen-single {
            height: 38px;
            border: 1px solid $color-light-blue;
            border-radius: 4px;
            color: $color-blue-gray;
            background: white;
            font-family: $primary-font;
            font-size: 16px;
            font-weight: 400;
            text-align: center;

            &:hover {
                font-weight: 400;
            }

            span {
                font-size: 16px;
                line-height: 34px;
                text-align: left;
            }

            div b {
                background-position-y: 8px;
            }
        }
    }

    .pagination-container {
        margin: 20px 0;

        ul.pagination {
            li {
                margin-right: 5px;

                a {
                    border: 2px rgba(100, 100, 108, 0.1) solid;
                    border-radius: 5px;
                    padding: 6px 12px;
                    color: $color-blue;
                    font-weight: 700;

                    &:hover {
                        color: $color-red;
                        text-decoration: none;
                    }
                }

                &.active {
                    a {
                        color: white;
                        background-color: $color-red;
                        border: 2px solid $color-red;
                    }
                }
            }
        }
    }

    .adress-block {
        border: $color-light-blue 1px solid;
        padding: 20px;
        margin-bottom: 20px;
    }

    .fa {
        &.ProtectionExpireSoon {
            color: yellow;
        }

        &.ProtectionActive {
            color: green;
             &.ProtectionUnPaid:before {
                 content:"\f05a"
           
            }
        }
        &.ProtectionExpired {
            color: red;
        }
         
    }
    /* Col Seperator*/
    .row.col-seperator {
        .col {
            padding-left: 30px;
            padding-right: 30px;

            &:not(:first-child) {
                border-left: 1px solid $color-grey-3;
            }
        }
    }
    /*Bootstrap override*/
    nav.navbar {
        min-height: 50px;
        width: 100%;

        .navbar-collapse {
            @media (max-width: $screen-md-max) {
                margin-top: 50px;
            }

            ul.navbar-nav {
                justify-content: space-between;
                width: 100%;

                li.nav-item {
                    a {
                        padding: 0;
                        display: initial;
                        font-size: 20px;
                        font-weight: 700;
                        color: $color-light-blue;
                    }

                    &.dropdown {
                        .dropdown-menu {
                            padding: 10px;
                            width: 210px;
                            a{
                                 font-size: 18px;
                                font-weight:300;
                            }
                        }
                    }
                }
            }
        }
    }

    .form-group {
        position: relative;

        label {
            display: block;
        }

        .form-control {
            border: 1px solid $color-light-blue;
            border-radius: 4px;
            color: $color-blue-gray;
            font-family: $primary-font;
            font-size: 16px;
            font-weight: 300;
            &:disabled {
                cursor: not-allowed;
                background-color:$color-grey-1;   
                  border: 1px solid $color-grey-3;
                /*background: url('../img/ico-lock.png') right center no-repeat;*/       
            }
        }
        .chosen-disabled{
            a{
                 border: 1px solid $color-grey-3;
                 box-shadow:none;
                 background-color:$color-grey-1;
            }
        }
       



        .form-check {
            display: inline-block;
            margin-right: 20px;

            .form-check-label {
                padding-left: 0;
               
            }
            label {
                font-weight: 300;
                font-size: 16px;
                color: $color-main-text;
                display: flex;
            }
            
        }

        &.required:before {
            content: '*';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -15px;
            color: $color-blue-gray;
        }
    }

     

label {
    font-size: 18px;
    font-weight: 700;
    color: $color-blue;
}

    .tab-content {
        position: relative;
        z-index: 99;
        background-color: white;
        box-shadow: 0 0 20px 1px rgba(0,0,0,0.2);
        border-radius: 0 10px 10px 10px;

        .tab-pane {
            padding: 0px 40px 40px 40px;
            margin-bottom: 30px;
        }
    }

    .modal-content {
        border-radius: 10px;
    }

    .modal-body {
        background-color: $color-grey-1;
        border-radius: 10px;
    }

    .modal-lg {
        width: 90%;
        max-width: 1260px;
    }

