.btn {
  @include button-size(8px, 30px, 1em, 10px);
  //text-transform: uppercase;
  white-space: normal;
  font-family: $secondary-font;
  font-weight: 400;
  transition: all 0.3s ease;
  border-width: 2px;
  margin-bottom: 20px;
  cursor:pointer;

  img{
    margin-right: 5px;
    &.over{
      display: none;
    }
  }

  &:hover {
    img{
      &.over{
        display:inline-block;
      }
      &.out{
        display: none;
      }
    }
  }

  .fa{
      margin-right: 5px;
  }
}

.btn-red {
  @include button-variant(white, $color-red, $color-red);
  &:hover, &:focus, &.unselected {
    color: $color-red;
    background-color: transparent;
    border-color: $color-red;
  }
}


.btn-green {
  @include button-variant(white, $color-green, $color-green);
  &:hover, &:focus, &.unselected {
    color: $color-green;
    background-color: transparent;
    border-color: $color-green;
  }
}


.btn-blue {
  @include button-variant(white, $color-blue-gray, $color-blue-gray);
  &:hover, &:focus, &.unselected {
    color: $color-blue-gray;
    background-color: transparent;
    border-color: $color-blue-gray;
  }
}

.btn-darkblue {
  @include button-variant(white, $color-dark-blue, $color-dark-blue);
  &:hover, &:focus, &.unselected {
    color: $color-dark-blue;
    background-color: transparent;
    border-color: $color-dark-blue;
  }
}

/*.btn-delete{
  font-size: 16px;
  font-weight: bold;
  color: $color-red;
  border: 2px solid $color-red;
  border-radius: 50%;
  padding: 5px 11px;
  transition: all 0.3s ease;
  &:hover{
    color: $color-blue;
    text-decoration: none;
    border-color: $color-blue;
  }
}*/

.btn-delete{

    background: url('../img/delete.png') center center no-repeat;
    display: inline-block;
    width:30px;
    height:30px;
    padding:0;
}
.ico-view{
    background: #f4f5f6 url('../img/ico-view.png') center center no-repeat;
    display: block;
    width:60px;
    height:60px;
    padding:0;
    &:hover{background-color: darken(#f4f5f6, 10%);}
}
.ico-edit{
    background: #fbfafa url('../img/ico-edit.png') center center no-repeat;
    display: block;
    width:60px;
    height:60px;
    padding:0;
   &:hover{background-color: darken(#fbfafa, 10%);}
}

.action-ico-view{
     background: url('../img/ico-view.png') left center no-repeat;
     padding-left:30px;    
     font-weight:600;
     color:$color-red;     
}

.action-link-ico{   
    display: inline-block;
    width:60px;
    height:60px;
    padding:0;
    &.action-link-ico-view{ background: url('../img/ico-view.png') center center no-repeat;}
    &.action-link-ico-edit{ background: url('../img/ico-edit.png') center center no-repeat;}
    &.action-link-ico-delete{ background: url('../img/ico-delete.png') center center no-repeat;}
}

a.action-link{
     font-weight:600;
     color:$color-red;     
     &.light{
          font-weight:300;
     }
}
a.action-link.inverted{
     font-weight:600;
     color:$color-dark-blue-text;     
}
.btn-small {
    padding: 8px 12px;
    font-size: 13px;
}
/*
input[type="button"].bg-red:hover, input[type="button"].bg-red:focus, input[type="reset"].bg-red:hover, input[type="reset"].bg-red:focus, input[type="submit"].bg-red:hover, input[type="submit"].bg-red:focus, .button.bg-red:hover, .button.bg-red:focus {
    background-color: #fff;
    color: #c02637;
    border-color: #c02637;
}
input[type="button"].bg-red, input[type="reset"].bg-red, input[type="submit"].bg-red, .button.bg-red {
    background-color: #c02637;
    color: #fff;
}
input[type="button"]:hover, input[type="button"]:focus, input[type="reset"]:hover, input[type="reset"]:focus, input[type="submit"]:hover, input[type="submit"]:focus, .button:hover, .button:focus {
    background-color: #c02637;
    color: #fff;
    border-color: #c02637;
    text-decoration: none;
}
input[type="button"], input[type="reset"], input[type="submit"], .button {
    font-size: 12px;
    //font-size: 1.2rem;
    display: block;
    max-width: 370px;
    margin: 0 auto;
    padding: 9px 20px;
    cursor: pointer;
    line-height: 1;
    text-shadow: none;
    outline: none;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-smoothing: antialiased;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
    color: #222228;
    font-weight: 600;
    text-transform: uppercase;
    font-family: "Open Sans", "Helvetica", "arial";
    text-align: center;
    -webkit-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    border: 3px solid #c02637;
    letter-spacing: 0.2em;
    text-decoration: none;
}
    */