@include create-helpers(margin-right);
@include create-helpers(margin-left);
@include create-helpers(margin-top);
@include create-helpers(margin-bottom);
@include create-helpers(margin);

@include create-helpers(padding-right);
@include create-helpers(padding-left);
@include create-helpers(padding-top);
@include create-helpers(padding-bottom);
@include create-helpers(padding);

//@include create-helpers-vw(margin-right);
//@include create-helpers-vw(margin-left);
//@include create-helpers-vw(margin-top);
//@include create-helpers-vw(margin-bottom);
//@include create-helpers-vw(margin);
//
//@include create-helpers-vw(padding-right);
//@include create-helpers-vw(padding-left);
//@include create-helpers-vw(padding-top);
//@include create-helpers-vw(padding-bottom);
//@include create-helpers-vw(padding);

.cover-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
}