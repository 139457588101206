.flex {
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW - Chrome */
  display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-box-flex: 1;      /* OLD - iOS 6-, Safari 3.1-6 */
  -moz-box-flex: 1;         /* OLD - Firefox 19- */
  //width: 20%;               /* For old syntax, otherwise collapses. */
  -webkit-flex: 1;          /* Chrome */
  -ms-flex: 1;              /* IE 10 */
  flex: 1;                  /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

.flex-grow-1{
  flex-grow: 1;
}

.flex-row {
  @extend .flex;
  flex-direction: row;
}

.flex-column {
  @extend .flex;
  flex-direction: column;
}

.no-flex-xs{
  @media (max-width: $screen-xs-max ){
    display: block;
  }
}

.no-flex-sm{
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    display: block;
  }
}

.no-flex-md{
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    display: block;
  }
}

.no-flex-lg{
  @media (min-width: $screen-lg-min) {
    display: block;
  }
}

.flex-ai-center {
  align-items: center;
}

.flex-ai-flex-end {
  align-items: flex-end;
}

.flex-ai-flex-start {
  align-items: flex-start;
}

.flex-ai-stretch {
  align-items: stretch;
}

.flex-ai-baseline {
  align-items: baseline;
}

.flex-jc-center {
  justify-content: center;
}

.flex-jc-flex-end {
  justify-content: flex-end;
}

.flex-jc-flex-start {
  justify-content: flex-start;
}

.flex-jc-space-around {
  justify-content: space-around;
}

.flex-jc-space-between {
  justify-content: space-between;
}

.flex-wrap-wrap{
  flex-wrap: wrap;
}

.flex-wrap-nowrap{
  flex-wrap: nowrap;
}

.flex-wrap-wrap-reverse{
  flex-wrap: wrap-reverse;
}
