﻿@include media-breakpoint-down(md) {
    .modal-lg {
        width: 100% !important;
        height: 100%;
        margin: 0px;



        .modal-content {
            border: none;
            height: 100%;
            border-radius: 0px;

            .modal-body {
                border-radius: 0;
            }
        }
    }
}

.modal-header {
    background-color: $color-red;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    h5 {
        color: $color-white-title;
    }

    .close {
        color: $color-white-title;
        opacity: 1;
    }
}