$bar-1-pos: 0;
$bar-2-pos: 9px;
$bar-3-pos: $bar-2-pos*2;

.main-menu-icon {
  margin: 0 10px 0 0;
  width: 32px;
  height: 20px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

.main-menu-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: $color-white;
  border-radius: 0px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.main-menu-icon span:nth-child(1) {
  top: $bar-1-pos;
}

.main-menu-icon span:nth-child(2),.main-menu-icon span:nth-child(3) {
  top: $bar-2-pos;
}

.main-menu-icon span:nth-child(4) {
  top: $bar-3-pos;
}

.main-menu-icon.open span:nth-child(1) {
  top: $bar-2-pos;
  width: 0%;
  left: 50%;
}

.main-menu-icon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.main-menu-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.main-menu-icon.open span:nth-child(4) {
  top: $bar-2-pos;
  width: 0%;
  left: 50%;
}
