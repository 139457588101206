html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

body {
  font-family: $primary-font;
  font-size: $font-size-base;
  line-height: $line-height-base;
  font-weight: $font-weight-base;
  color: $color-main-text;
  background-color: $color-grey-1;
  &.login{
      height:100%;
      background: url(/UI/img/bg-identification.jpg) no-repeat right center fixed; 
      background-size: cover;
  }
}

a:not(.btn), a:not(.btn):hover {
    color: $color-main-text;
    //font-weight: 700;
    //text-decoration: underline;
}
