header {
    background-color: white;

    img.logo {
        @media (max-width: $screen-md-max) {
            margin: auto;
            display: block;
        }
    }

    .logo-container {
        background-image: url(../img/logo-w-txt-fr.svg);
        background-repeat: no-repeat;
        display: block;
        text-indent: -9999px;
        width: 60%;
        max-width: 260px;
        margin: 0 auto;
        height: 40px;
        background-position: center center;
    }

    .navbar a {
        position: relative;
        font-weight: 300;
        color: $color-light-blue !important;
        transition: all 0.3s ease;

        &:before {
            content: '';
            display: block;
            position: absolute;
            background-color: $color-light-blue;
            width: 0%;
            height: 1px;
            bottom: -2px;
            transition: all 0.3s ease;
        }

        &:hover, &:focus {
            color: $color-light-blue;
            text-decoration: none;

            &:before {
                width: 100%;
            }
        }

        &.active {
            color: $color-red;
            font-weight: 700;
        }
    }

    .connection {
        padding: 10px 0;
        background-color: $color-grey-2;

        a {
            font-size: 14px;
        }
    }

    .main-menu {
        padding: 20px 0;
        -webkit-box-shadow: 0px 11px 18px -8px rgba(0,0,0,0.6);
        -moz-box-shadow: 0px 11px 18px -8px rgba(0,0,0,0.6);
        box-shadow: 0px 11px 18px -8px rgba(0,0,0,0.6);
        position: relative;

        .logo {
            max-height: 50px;
        }
    }

    .bg-image {
        @extend .flex;
        align-items: center;
        justify-content: flex-start;
        height: 180px;
        background-image: url('../img/bg-header.jpg');
        background-position: right center;
        background-repeat: no-repeat;
        background-color: #F4F5F7;
        margin-bottom: 20px;

        h2 {
            margin-top: 10px;
            font-weight: 600;
        }
    }

    .bg-image-membre {
        @extend .flex;
        align-items: center;
        justify-content: flex-start;
        height: 260px;
        background-image: url('../img/img-partnership_1600x500.jpg');
        background-size: cover;
        background-position: right -60px;
        background-repeat: no-repeat;
        background-color: #F4F5F7;
        margin-bottom: 20px;

        h2 {
            margin-top: 10px;
            font-weight: 600;
        }
    }

    &.login {
        padding: 40px 0;
    }
}

@include media-breakpoint-down(lg) {
    header {
        .bg-image-membre {
            background-position: right -50px;
            background-size: cover;
            height: 260px;

            .container {
                display: none;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    header {
        .bg-image-membre {
            background-position: center -40px;
            background-size: cover;
            height: 240px;

            .container {
                display: none;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    header {
        .bg-image-membre {
            background-position: center center;
            background-size: cover;
            height: 180px;

            .container {
                display: none;
            }
        }
    }
}