﻿.protection-membre-detail {
    margin-top: 40px;

    h3 {
        color: #2A2930;
        margin: 15px 0 0;
        font-size: 21px;
    }

    a.quit {
        position: absolute;
        right: 0;
        font-size: 20px;
        color: #95989A;
        -webkit-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        margin-right: 28px;

        span {
            font-family: $secondary-font;
            color: $color-red;
            font-weight: bold;
        }
    }

    .form-section h2 {
        text-align: left;
        font-family: $secondary-font;
        color: #2A2930;
        font-size: 32px;
        //margin-left: -15px;
        //margin-bottom: 15px;
    }

    label.produits {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        font-size: 18px;
        line-height: 20px;
        font-weight: 500;
        color: #2A2930;
        margin-bottom: 15px;

        span {
            font-size: 12px;
            line-height: 14px;
        }
    }

    label.table-checkbox {
        display: inline-block;
    }

    label {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        color: #2A2930;
        margin-bottom: 10px;
    }

    .table-wrapper {
        table#MembreListDiv {
            margin-bottom: 16px;
        }

        text-align: left;
    }

    div.total {
        font-size: 20px;
        font-family: $secondary-font;
        margin-bottom: 20px;
    }
}

@include media-breakpoint-down(sm) {
    .protection-membre-detail {
        a.quit {
            font-size: 16px;
            top: -28px;
            margin-right: 15px;
        }
    }
}