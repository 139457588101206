﻿.breacrumb-wrapper {
    background-color: #2A2930;
    height: 84px;

    .breadcrumb {
        background-color: transparent;
        margin: 0;
        padding: 0;

        .breadcrumb-item {
            margin: 0;
            padding: 0;
            height: 100%;
            width: 292px;
            margin-left: -20px;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;

            &::before {
                content: none;
            }

            a {
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -webkit-justify-content: center;
                -ms-flex-pack: center;
                justify-content: center;
                position: relative;
                height: 84px;
                background-color: #3F3F44;
                -webkit-box-flex: 1;
                -webkit-flex-grow: 1;
                -ms-flex-positive: 1;
                flex-grow: 1;
                padding-left: 70px;

                .puce {
                    position: absolute;
                    left: 20px;
                    top: 50%;
                    -webkit-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
                    display: -webkit-box;
                    display: -webkit-flex;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -webkit-align-items: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -webkit-justify-content: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    border-radius: 50%;
                    width: 30px;
                    height: 30px;
                    background-color: white;

                    span {
                        font-family: "Arvo", "Helvetica", "arial";
                        font-size: 22px;
                        font-weight: 700;
                        color: #3F3F44;
                        text-decoration: none;
                    }

                    .fa {
                        display: none;
                        color: white;
                        font-size: 20px;
                    }
                }

                > span {
                    color: white;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 16px;
                    text-decoration: none;
                }
            }

            &.active.current {
                a {
                    background-color: #BD1830;

                    .puce {
                        background-color: white;

                        span {
                            display: block;
                            color: #BD1830;
                        }

                        .fa {
                            display: none;
                        }
                    }
                }

                .left-side {
                    .top {
                        border-color: transparent #BD1830 transparent transparent;
                    }

                    &.full {
                        .top {
                            background-color: #BD1830;
                        }

                        .bottom {
                            background-color: #BD1830;
                        }
                    }

                    .bottom {
                        border-color: transparent transparent #BD1830 transparent;
                    }
                }

                .right-side {
                    border-color: transparent transparent transparent #BD1830;
                }
            }

            &.active {
                a {
                    cursor: pointer;

                    .puce {
                        background-color: #00AA00;

                        span {
                            display: none;
                        }

                        .fa {
                            display: block
                        }
                    }
                }

                .left-side {
                    height: 100%;

                    &.full {
                        /*
                        .top {
                            border: none;
                            width: 30px;
                            height: 42px;
                            background-color: #3F3F44;
                        }
*/
                        .bottom {
                            // background-color: #BD1830;
                        }
                    }

                    .top {
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0 30px 42px 0;
                        border-color: transparent #3F3F44 transparent transparent;
                    }
                }
            }

            .left-side {
                height: 100%;

                &.full {
                    .bottom {
                        border: none;
                        width: 30px;
                        height: 42px;
                        background-color: #3F3F44;
                    }

                    .top {
                        border: none;
                        width: 30px;
                        height: 42px;
                        background-color: #3F3F44;
                    }
                }

                .bottom {
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 0 42px 30px;
                    border-color: transparent transparent #3F3F44 transparent;
                }

                .top {
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 30px 42px 0;
                    border-color: transparent #3F3F44 transparent transparent;
                }
            }

            .right-side {
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 42px 0 42px 30px;
                border-color: transparent transparent transparent #3F3F44;
            }

            &:first-of-type {
                margin-left: 0;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .breacrumb-wrapper {
        .breadcrumb {
            .breadcrumb-item {
                width: 25%;
                margin-left: 0;

                .left-side {
                    display: none;
                }

                .right-side {
                    display: none;
                }

                a {
                    padding-left: 0;

                    .puce {
                        left: 50%;
                        -webkit-transform: translate(-50%, -50%);
                        -ms-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
                    }

                    > span {
                        display: none;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-only(lg) {

    .breacrumb-wrapper {
        .breadcrumb {
            .breadcrumb-item {
                width: 247px;
            }
        }
    }
}
