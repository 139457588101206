﻿ul.parsley-errors-list {
  padding: 0;
  text-align: left;
  margin: -15px 0 0 0;

  li {
    list-style: none;
    color: $color-white;
    font-size: 0.8em;
  }
}

textarea{
    height:150px;
}

textarea.bigtextarea {
    height: 400px;
}

/*custom radio and checkbox styling*/
.container:not(.simple-checkbox) {
    input[type="radio"], input[type="checkbox"] {
        position: absolute;
        visibility: hidden;
    }

    input[type="radio"] ~ .dummy-radio {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid $color-blue-gray;
        padding: 3px;
        margin-right: 6px;

        &:before {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            border-radius: 50%;
        }
    }

    input[type="radio"]:checked ~ .dummy-radio {
        &:before {
            background-color: $color-blue;
        }
    }

    input[type="checkbox"] ~ .dummy-checkbox {
        display: inline-block;
        //display:none;
        width: 20px;
        height: 20px;
        //border-radius: 50%;
        border: 1px solid  $color-blue;
        padding: 3px;
        margin-right: 6px;

        &:before {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            //border-radius: 50%;
        }
    }
   input[type="checkbox"]:disabled ~ .dummy-checkbox{
       border: 1px solid  $color-grey-3;
   }

    input[type="checkbox"]:checked ~ .dummy-checkbox {
        &:before {
             content: '✓';
             color:$color-green;
             top:-8px;
             left:-1px;
             position:relative;
             font-size: 18px;
             font-weight: 700;

        }
    }
}

.container:not(.simple-checkbox) {

    input[type="radio"]:checked ~ .dummy-radio.dummy-radio--red {
        &:before {
            background-color: $color-red;
        }
    }
} 

label.table-checkbox{
    margin:0;
    margin-top:10px;
}

.edit-membre {
    .chosen-disabled {
        opacity: 1 !important;

        a:hover {
            color: #597a96;
            cursor: not-allowed;
        }
    }
}

