@mixin create-helpers($props) {
  $i: 0;
  @while $i <= 50 {

    .#{$props}-#{$i} {
      #{$props}: #{$i}px;
    }

    .#{$props}-#{$i}-xs {
      @media (min-width: $screen-xs-min) {
        #{$props}: #{$i}px !important;
      }
    }

    .#{$props}-#{$i}-sm {
      @media (min-width: $screen-sm-min) {
        #{$props}: #{$i}px !important;
      }
    }

    .#{$props}-#{$i}-md {
      @media (min-width: $screen-md-min) {
        #{$props}: #{$i}px !important;
      }
    }

    .#{$props}-#{$i}-lg {
      @media (min-width: $screen-lg-min) {
        #{$props}: #{$i}px !important;
      }
    }

    .#{$props}-#{$i}-xl {
      @media (min-width: $screen-xl-min) {
        #{$props}: #{$i}px !important;
      }
    }

    $i: $i+5;
  }
}

@mixin create-helpers-vw($props) {
  $i: 0;
  $j: 0;
  @while $i <= 5 {
    .#{$props}-#{$j}-vw {
      #{$props}: #{$i}vw;
    }
    $i: $i+0.5;
    $j: $j+5;
  }
}