@charset 'UTF-8';

@import 'dependencies/bootstrap4.6/_bootstrap';
@import 'dependencies/font-awesome/scss/font-awesome';

/*! GENI CSS */

@import
  'geni/abstracts/variables',
  'geni/abstracts/mixins';

@import
  'geni/base/typography',
  'geni/base/helpers',
  'geni/base/flex',
  'geni/base/base';

@import
  'geni/components/navbar',
  'geni/components/tabs-nav',
  'geni/components/hamburger',
  'geni/components/button',
  'geni/components/form',
  'geni/components/responsive-table',
  'geni/components/breadcrumb',
  'geni/components/modal',
  'geni/components/table';

@import
  'geni/layout/header',
  'geni/layout/sidebar',
  'geni/layout/footer';

@import
  'geni/pages/common',
  'geni/pages/protection-membre',
  'geni/pages/protection-detail',
  'geni/pages/section-membre',
  'geni/pages/sections';