﻿.protection-member--tagline {
    padding-top: 80px;
    padding-bottom: 80px;


    h2 {
        font-family: $secondary-font;
        font-size: 40px;
        color: #222228;
        text-align: center;
    }
    
    a.quit {
        position: absolute;
        top: 20px;
        right: 0;
        font-size: 20px;
        color: #95989A;
        -webkit-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        margin-right: 28px;

        span {
            font-family: $secondary-font;
            color: $color-red;
            font-weight: bold;
        }
    }


    .pastille-promo {
        position: absolute;
        right: 3%;
        bottom: -182px;
        z-index: 100;

        .pastille-info {
            font-family: $secondary-font;
            color: $color-blue-caa;
            background: url(../img/arrow.png) no-repeat bottom left;
            padding-bottom: 50px;
            position: absolute;
            top: -82px;
            left: 110px;
            width: 100%;
        }

        .pastille {
            border-radius: 50%;
            width: 120px;
            height: 120px;
            background: $color-blue-caa;
            z-index: 100;

            p {
                color: #FFF;
                text-transform: uppercase;
                font-size: 12px;
                line-height: 14px;
                text-align: center;
                margin-bottom: 0;
                margin-top: 17px;

                span {
                    font-size: 48px;
                    line-height: 48px;
                    font-weight: bold;
                    display: block;
                }
            }
        }
    }
}

.protection-member--table {
    -webkit-box-shadow: 1px 1px 33px 3px rgba(0, 0, 0, 0.33);
    -moz-box-shadow: 1px 1px 33px 3px rgba(0, 0, 0, 0.33);
    box-shadow: 1px 1px 33px 3px rgba(0, 0, 0, 0.33);
    padding: 0;

    .promo {
        background: $color-blue-caa;
        padding: 10px 0;

        p {
            color: #FFF;
            font-size: 14px;
            text-transform: uppercase;
            text-align: center;
            margin: 0;
        }
    }

    .thead {
        .col-thead {
            .page-template-page-adherez .col-thead .pastille-promo {
                position: static;
                right: auto;
                bottom: auto;
                margin-top: 33px;
            }

            .pastille-promo {
                position: static;
                right: auto;
                bottom: auto;
                margin-top: 33px;

                p.pastille-info {
                    background: none;
                    position: relative;
                    top: auto;
                    left: auto;
                    padding: 25px 0 0 0;
                    margin: 0 0 20px 0;
                    font-family: $secondary-font;
                    color: $color-blue-caa;
                    width: 100%;
                }

                .pastille {
                    border-radius: 50%;
                    width: 120px;
                    height: 120px;
                    background: $color-blue-caa;
                    z-index: 100;
                    margin: 0 auto;

                    p {
                        color: #FFF;
                        text-transform: uppercase;
                        font-size: 12px;
                        line-height: 14px;
                        text-align: center;
                        margin-bottom: 0;
                        margin-top: 17px;
                        padding: 0;

                        span {
                            font-size: 48px;
                            line-height: 48px;
                            font-weight: bold;
                            display: block;
                        }
                    }
                }
            }

            @include media-breakpoint-only(xl) {
                .pastille-promo {
                    display: none !important;
                }
            }

            &:nth-child(odd) {
                background: white;
                border-bottom: 3px solid #eff3f8;
            }

            &:nth-child(even) {
                background: #eff3f8;
                border-bottom: 3px solid white;
            }
        }


        h3 {
            font-family: $secondary-font;
            color: #292b2c;
            font-size: 30px;
            text-align: left;
            margin-bottom: 20px;
        }

        p {
            font-size: 14px;
            color: #292b2c;
            //font-size: 1.4rem;
            font-family: $primary-font;
            line-height: 20px;
            margin-bottom: 20px;

            &:first-child {
                text-align: center;
                padding-top: 25px;
            }

            span {
                font-size: 71px;
                font-weight: bold;
                line-height: 70px;
                letter-spacing: -3px;
                display: block;

                sup {
                    top: -25px;
                    font-size: 50%;
                }
            }
        }
    }

    .tbody {
        .col-tbody {
            min-height: 70px;
            padding-top: 5px;
            padding-bottom: 5px;

            span.on {
                display: block;
                background: #0d4784;
                border-radius: 50%;
                width: 20px;
                height: 20px;
            }

            &:first-child {
                background: white;
                border-bottom: 3px solid #eff3f8;
            }

            &:nth-child(odd) {
                background: #eff3f8;
                border-bottom: 3px solid white;
            }

            &:nth-child(even) {
                background: white;
                border-bottom: 3px solid #eff3f8;
            }
        }

        .col-tbody-legend {
            font-size: 14px;
            //font-size: 1.4rem;
        }
    }

    .mobile {
        .col-adh {
            background: #eff3f8;
            margin-bottom: 16px;
        }

        .thead .col-thead {
            border-bottom: 3px solid #eff3f8;
            background: #eff3f8 !important;
            border-bottom: 3px solid white !important;

            h3 {
                text-align: center;
            }

            p {
                text-align: center;

                &:first-child {
                    padding-top: 25px;
                }
            }
        }

        .tbody .col-body {
            font-size: 14px;
            //font-size: 1.4rem;
            min-height: 70px;
            border-bottom: 3px solid #eff3f8;
            border-bottom: 3px solid white;
            text-align: center;

            &:last-child {
                border-bottom: 0;
            }
        }
        /*
        .col-adh:nth-child(odd) {
            background: #eff3f8;

            .thead {

                .col-thead {
                    background: #eff3f8 !important;
                    border-bottom: 3px solid white !important;
                }
            }



            .col-body {
                border-bottom: 3px solid white;
            }
        }
            */
    }
}

.notesLegales {
    padding: 60px 0;

    .card {
        border-color: #E9EEF5;
        border-radius: 0;
        margin-bottom: 10px;

        .card-header {
            padding: 0;
            margin-bottom: 0;
            background-color: #F8F8F8;
            border-bottom: none;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;

            h3 {
                -webkit-box-flex: 1;
                -webkit-flex-grow: 1;
                -ms-flex-positive: 1;
                flex-grow: 1;
                font-family: "Arvo", "Helvetica", "arial";
                font-size: 17px;
                line-height: 24px;
                padding-left: 30px;
                text-align: left;
                margin-bottom: 0px;
            }
        }

        .card-block {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;

            ul li {
                line-height: 22px;
                text-align: left;
            }
        }
    }

    .btn-darkblue-plus {
        color: white;
        background-color: #3F3F44;
        margin-bottom: 0;
        padding: 7px 18px;
        border-radius: 0;
        font-size: 12px;
        letter-spacing: normal;
        font-weight: 700;
        text-transform: uppercase;
        white-space: normal;
        -webkit-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        border-width: 0px;
        cursor: pointer;

        i {
            margin-right: 0;
        }
    }
}

.security-icon {
    margin-bottom: 10px;
}
@include media-breakpoint-up(md) {
    .credit-card {
        bottom: 0;
        position: absolute;
    }
}
.credit-card-wrapper {
    position: relative;

    .credit-card {
        max-height: 37px;

        margin-bottom: 16px;
    }
}

.protection-membre-detail {
    select {
        //width: auto;
    }
}



@include media-breakpoint-down(lg) {
    .protection-member--tagline {
        padding-top: 35px;
        padding-bottom: 50px;

        h2 {
            font-size: 28px;
        }

        .pastille-promo {
            display: none;
        }
    }
}
