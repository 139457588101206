﻿table.simple-table-responsive td{
    padding: 0 10px;
    .btn-small {
        margin-bottom: 0;
    }
}
@include media-breakpoint-down(sm) {
    .table-membres{
        tr{
            min-height: 275px;
        }
    }
        
    table.simple-table-responsive {
        border: 0;

        caption {
            font-size: 1.3em;
        }

        thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: .625em;
        }

        td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: 14px;
            padding: 4px 0px !important;
            text-align: right !important;
            border-top: 0 !important;
            border-left: 0 !important;
            border-right: 0 !important;
            height: 36px !important;
            line-height: 28px;

            .btn-small {
                padding: 4px 12px;
            }
        }

        td::before {
            /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            text-transform: uppercase;
        }

        td:first-child {
            margin-top: 16px;
        }

        td:last-child {
            border-bottom: 0;
        }
    }
}
