// Fonts
// @type List
@import url('https://fonts.googleapis.com/css?family=Arvo:400,400i,700,700i');

$primary-font: Calibri,Candara,Segoe,Segoe UI,Optima,Arial,sans-serif !important;
$secondary-font: 'Arvo', serif !important;

$line-height-base: 1.6;
$font-size-base: 16px;
$font-weight-base: 400;

// Headlines
$font-size-h1: 3rem ;
$font-size-h2: 2.3rem ;
$font-size-h3: 1.75rem ;
$font-size-h4: 1.5rem ;
$font-size-h5: 1.25rem ;
$font-size-h6: 1rem ;

$max-width: 1600px !default;

//Colors
$color-dark-blue: #012b4b;
$color-blue: rgb(0, 68, 120);
$color-blue-title: #597a96;
$color-blue-gray: #597a96;
$color-white-title: #fdfbfb;
$color-dark-blue-text: #2e2e3e;
$color-red: #BD1830;
$color-light-blue: #7c8e9e;
$color-grey-1: #f8fafc;
$color-grey-2: #f1f5f8;
$color-grey-3: #ecf1f5;
$color-main-text: $color-dark-blue-text;
$color-white: #ecf1f5;
$color-green:#01A54F;
$color-orange:#FF6600;
$color-teal: #00aebb;
$color-yellow: #d0b355;
$color-pink: #bd276e;
$color-blue-caa: #124882;
$screen-xs: map-get($grid-breakpoints, xs) !default;
$screen-xs-min:              $screen-xs !default;
$screen-sm:                  map-get($grid-breakpoints, sm) !default;
$screen-sm-min:              $screen-sm !default;
$screen-md:                  map-get($grid-breakpoints, md) !default;
$screen-md-min:              $screen-md !default;
$screen-lg:                  map-get($grid-breakpoints, lg) !default;
$screen-lg-min:              $screen-lg !default;
$screen-xl:                  map-get($grid-breakpoints, xl) !default;
$screen-xl-min:              $screen-xl !default;

$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;
$screen-lg-max:              ($screen-xl-min - 1) !default;



