footer {
    background: $color-dark-blue;
    padding: 20px 0;
    color: white;
    text-align: center;

    &.login {
        position:fixed;
        bottom:0;
        width:100%;
    }
}
