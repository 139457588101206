.connection nav {
    text-align: right;

    ul {
        padding: 0;
        margin: 0;

        li {
            display: inline-block;
            list-style: none;
            border-left: 2px solid $color-red;
            margin-left: 5px;
            padding-left: 5px;
            line-height: 1em;

            &:first-child {
                border-left: none;
            }
        }
    }
}
@include media-breakpoint-down(md) {
    nav.navbar {
        top: -52px;
    }
}